import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import format from 'date-fns/format';
import isPast from 'date-fns/isPast';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  Container,
  Box,
  Text,
  HStack,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import SEO from 'timelesstime-ui/components/seo';
import { EventLeadInHeader } from 'timelesstime-ui/components/lead-in-header';
import Html from 'timelesstime-ui/components/html';
import NewBadge from 'timelesstime-ui/components/new-badge';
import GoogleMap from 'timelesstime-ui/components/google-map';
import NewsletterSignup from 'timelesstime-ui/components/newsletter-signup';
import Heading from 'timelesstime-ui/components/heading';
import ButtonLink from 'timelesstime-ui/components/button-link';
import Jumbotron from 'timelesstime-ui/components/jumbotron';
import PageLayout from '../components/layout';

import EventStructuredData from '../components/structured-data/event';
import TypeBadge from '../components/type-badge';

const GetTickets = ({
  eventTitle,
  isPastEvent,
  eventbriteEventId,
  isSoldOut,
  ticketUrl,
  informationUrl,
}) => {
  if (isPastEvent) {
    return (
      <Alert status="warning" my={8}>
        <AlertIcon />
        <Box flex="1">
          <AlertTitle>
            This is a past event and ticket sales have finished
          </AlertTitle>
          <AlertDescription display="block">
            Why not take a look at{' '}
            <CanonicalLink color="orange.500" to="/events/">
              our upcoming events
            </CanonicalLink>
            ?
          </AlertDescription>
        </Box>
      </Alert>
    );
  }
  if (eventbriteEventId) {
    if (isSoldOut) {
      return (
        <Text>
          Sorry, we&apos;ve run out of tickets. Head over to our
          <CanonicalLink color="orange.400" to="/contact">
            contact page
          </CanonicalLink>{' '}
          and let us know you&apos;re interested in coming. We&apos;ll see what
          we can do!
        </Text>
      );
    }
    return (
      <Box
        width="100%"
        textAlign="left"
        height={['400px', '400px', '500px', '600px']}
      >
        <iframe
          title={`${eventTitle} tickets`}
          src={`//eventbrite.com/tickets-external?eid=${eventbriteEventId}&ref=etckt`}
          frameBorder="0"
          width="100%"
          height="100%"
          vspace="0"
          hspace="0"
          marginHeight="5"
          marginWidth="5"
          scrolling="auto"
        />
      </Box>
    );
  }
  if (ticketUrl || informationUrl) {
    if (ticketUrl) {
      return (
        <ButtonLink
          colorScheme="orange"
          target="_blank"
          rel="noopener noreferrer"
          to={ticketUrl}
        >
          get a ticket
        </ButtonLink>
      );
    }
    if (informationUrl) {
      return (
        <ButtonLink
          colorScheme="orange"
          target="_blank"
          rel="noopener noreferrer"
          to={informationUrl}
        >
          find out more
        </ButtonLink>
      );
    }
  }
  return (
    <Text color="gray.400" my={4}>
      Details to be confirmed
    </Text>
  );
};

const EventPage = ({ data: { contentfulEventInstance: event } }) => {
  const {
    title,
    slug,
    type,
    metaDescription,
    locationName,
    address,
    ticketUrl,
    informationUrl,
    isSoldOut,
    isOnline,
    maxTicketPrice,
    minTicketPrice,
    latLng,
    startDate,
    endDate,
    createdAt,
    jsonLdFeaturedImage,
    jsonLdThumbnailImage,
    speakers,
    eventbriteEventId,
    bannerImage,
    bannerImageFallback,
    fields,
  } = event;
  const isPastEvent = startDate !== undefined && isPast(new Date(startDate));
  const {
    contentHtml,
    programmeContentHtml,
    locationContentHtml,
    faqContentHtml,
  } = fields;
  return (
    <PageLayout
      leadInHeader={
        <EventLeadInHeader
          heading={event.heading || event.title}
          crumbs={[
            {
              url: '/events/',
              title: 'Events',
            },
            {
              url: `/events/${event.slug}/`,
              title: event.title,
            },
          ]}
        />
      }
    >
      <SEO
        title={title}
        keywords={fields.keywords}
        description={metaDescription}
        canonicalPath={fields.path}
        thumbnail={jsonLdThumbnailImage}
      />
      <EventStructuredData
        slug={slug}
        title={title}
        speakers={speakers}
        description={metaDescription}
        startDate={new Date(startDate)}
        endDate={new Date(endDate)}
        locationName={locationName}
        address={address}
        latLng={latLng}
        isFree={
          (minTicketPrice === undefined || minTicketPrice === 0) &&
          (maxTicketPrice === undefined || maxTicketPrice === 0)
        }
        isOnline={event.isOnline}
        ticketUrl={ticketUrl}
        createdAt={new Date(createdAt)}
        minTicketPrice={minTicketPrice}
        maxTicketPrice={maxTicketPrice}
        isSoldOut={false}
        thumbnailImage={jsonLdThumbnailImage}
        featuredImage={jsonLdFeaturedImage}
      />

      <Container maxW="container.lg">
        <Box mt={4}>
          <CanonicalLink color="orange.500" to="/events/">
            back to all events
          </CanonicalLink>
        </Box>

        <Box as="header" mt={4} mb={4}>
          <Heading as="h1">{title}</Heading>
          <Text as="strong">{format(new Date(startDate), 'do MMMM yyyy')}</Text>
          <HStack spacing={1}>
            <TypeBadge type={type} title={type} />
            {isOnline && (
              <TypeBadge dark type="Online" title="Online only event" />
            )}
            <NewBadge isNew={fields.isNew} />
            <Box pl={2}>
              {(event.speakers && event.speakers.length) > 0 && (
                <>
                  Presented by
                  {event.speakers.map((speaker, index) => (
                    <Fragment key={speaker.id}>
                      {index > 0 && '&'} {speaker.firstName} {speaker.lastName}{' '}
                    </Fragment>
                  ))}
                </>
              )}
            </Box>
          </HStack>
          <Divider mt={2} mb={4} />
        </Box>

        {isPastEvent && (
          <Alert status="warning" my={8}>
            <AlertIcon />
            <Box flex="1">
              <AlertDescription display="block">
                This is a past event and ticket sales have finished. Take a look
                at{' '}
                <CanonicalLink color="orange.600" to="/events/">
                  our upcoming events
                </CanonicalLink>
              </AlertDescription>
            </Box>
          </Alert>
        )}

        <Html mt={4} mb={4} source={contentHtml} headerLevelStart={1} />

        {programmeContentHtml && (
          <>
            <Heading as="h2">Programme</Heading>
            <Html
              mt={4}
              mb={4}
              source={programmeContentHtml}
              headerLevelStart={2}
            />
          </>
        )}
      </Container>

      <Box width="100%" height="100px" overflow="hidden" my={8}>
        {bannerImage && (
          <GatsbyImage image={getImage(bannerImage)} alt={bannerImage.title} />
        )}
        {!bannerImage && (
          <GatsbyImage
            image={getImage(bannerImageFallback)}
            alt={bannerImageFallback.title}
          />
        )}
      </Box>

      <Container maxW="container.lg">
        <Heading as="h2">Location</Heading>
        <Text>
          {locationName}. {address}
        </Text>
        {locationContentHtml && (
          <Html source={locationContentHtml} headerLevelStart={2} />
        )}
        {!isOnline && latLng && latLng.lat && latLng.lon && (
          <Box width="100%" height="300px" my={4}>
            <GoogleMap
              center={{
                lat: latLng.lat,
                lng: latLng.lon,
              }}
              markerLocation={{
                lat: latLng.lat,
                lng: latLng.lon,
              }}
              zoom={12}
              draggable
              scrollwheel
              zoomControl
              mapTypeControl={false}
              scaleControl={false}
              streetViewControl={false}
              rotateControl={false}
              fullscreenControl={false}
            />
          </Box>
        )}
        {(speakers && speakers.length) > 0 && (
          <>
            <Heading as="h2">About the Presenters</Heading>
            {speakers.map((speaker) => (
              <Fragment key={speaker.id}>
                <Heading as="h3">
                  {speaker.firstName} {speaker.lastName}
                </Heading>
                <Html source={speaker.fields.bioHtml} headerLevelStart={2} />
              </Fragment>
            ))}
          </>
        )}
        {faqContentHtml && (
          <>
            <Heading as="h2">General FAQs</Heading>
            <Html source={faqContentHtml} headerLevelStart={2} />
          </>
        )}
        <Box my={4}>
          <Heading as="h2" id="get-tickets">
            Get Your Tickets Now
          </Heading>
          <GetTickets
            eventTitle={event.title}
            isPastEvent={isPastEvent}
            eventbriteEventId={eventbriteEventId}
            isSoldOut={isSoldOut}
            ticketUrl={ticketUrl}
            informationUrl={informationUrl}
          />
        </Box>
      </Container>

      <Jumbotron as="aside" py={8} bg="tt.darkBlue">
        <NewsletterSignup />
      </Jumbotron>
    </PageLayout>
  );
};

export const query = graphql`
  query EventSinglePageBySlug($slug: String!) {
    contentfulEventInstance(slug: { eq: $slug }) {
      id
      title
      slug
      heading
      type
      metaDescription
      locationName
      address
      ticketUrl
      informationUrl
      isSoldOut
      isOnline
      maxTicketPrice
      minTicketPrice
      latLng {
        lat
        lon
      }
      startDate
      endDate
      isPartOfSeries
      positionInSeries
      numberOfEventsInSeries
      #otherEventsInSeries {
      #    id
      #    title
      #    slug
      #    startDate
      #    endDate
      #}
      createdAt
      featuredImage {
        ...FeaturedImage
      }
      jsonLdFeaturedImage: featuredImage {
        ...JsonLdFeaturedImage
      }
      jsonLdThumbnailImage: featuredImage {
        ...JsonLdThumbnailImage
      }
      speakers {
        id
        firstName
        lastName
        email
        jobTitle
        fields {
          bioHtml
        }
      }
      eventbriteEventId
      fields {
        contentHtml
        programmeContentHtml
        locationContentHtml
        faqContentHtml
      }
      bannerImage {
        title
        gatsbyImageData(
          placeholder: BLURRED
          layout: FULL_WIDTH
          cropFocus: CENTER
          resizingBehavior: CROP
        )
      }
      bannerImageFallback: featuredImage {
        title
        gatsbyImageData(
          placeholder: BLURRED
          layout: FULL_WIDTH
          cropFocus: CENTER
          resizingBehavior: CROP
        )
      }
      fields {
        path
        url
        writtenAt
        isNew
        keywords
      }
    }
  }
`;

export default EventPage;
